<template>
  <div>
    <a-modal
      v-model="visibleModal"
      class="mod"
      :title="isEdit ? 'Form Edit Counseling Type' : 'Form Create Counseling Type'"
      @cancel="toggleModal()"
      centered
    >
      <form-counseling-type :newData="newData" @handle-change="handleChange" :isSubmit="isSubmit" @handle-validate="handleValidate" />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModal()">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModal"
          @click="handleOkModal"
        >
          {{ isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div>
        <a-button
          @click.prevent="fetchDataTable"
          size="large"
          class="mb-3 mb-lg-0 mr-0"
        >
          <a-icon type="sync" />
          REFRESH</a-button
        >
      </div>
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModal()"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Counseling Type</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="columns"
        :data-source="dataTable"
        :pagination="{ pageSize: 10, hideOnSinglePage: true, ...pagination }"
        :loading="loadingTable"
        @change="handleTableChange"
        bordered
      >
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModal(record)"
            class="mr-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="handleDelete(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const FormCounselingType = () => import('@/views/Teacher/Counseling/CounselingType/Form.vue')
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  components: { FormCounselingType },
  data() {
    return {
      pagination: { current: 1 },
      dataTable: [],
      columns,
      loadingTable: false,
      visibleModal: false,
      newData: {
        nama: null,
      },
      loadingActionModal: false,
      isEdit: false,
      isSubmit: false,
      editRecord: {},
    }
  },
  methods: {
    toggleModal(data) {
      this.visibleModal = !this.visibleModal
      if (!this.visibleModal) {
        this.newData = {
          nama: null,
        }
        this.isEdit = false
        this.editRecord = {}
      }

      if (data) {
        this.isEdit = true
        const { name: nama } = data
        this.newData = { nama }
        this.editRecord = data
      }
    },
    handleDelete(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete this counseling type?</div>
        ),
        onOk: async () => {
          try {
            await this.$store.dispatch('counseling/DELETE_COUNSELING_TYPE', { id: data.id })
            this.$notification.success({
              message: 'Success',
              description:
                'Counseling type is deleted.',
            })
            this.fetchDataTable()
          } catch (err) {
            console.log(err)
            this.$notification.error({
              message: 'Error.',
              description: 'Counseling type cannot be deleted.',
            })
          }
          this.fetchDataTable()
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
      })
    },
    handleChange(payload) {
      const { value, column } = payload
      this.newData[column] = value
    },
    handleOkModal() {
      this.isSubmit = true
    },
    handleValidate(payload) {
      if (payload) {
        this.loadingActionModal = true
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to { this.isEdit ? 'edit this' : 'add new'} counseling type?</div>
          ),
          onOk: async () => {
            const status = this.isEdit ? 'edited' : 'added'
            try {
              const dispatchStatus = this.isEdit ? 'PUT' : 'POST'
              const payloadEdit = this.isEdit ? { id: this.editRecord.id } : {}
              await this.$store.dispatch(`counseling/${dispatchStatus}_COUNSELING_TYPE`, { newData: this.newData, ...payloadEdit })
              this.$notification.success({
                message: 'Success',
                description:
                  `Counseling type is ${status}.`,
              })
              this.fetchDataTable()
            } catch (err) {
              console.log(err)
              this.$notification.error({
                message: 'Error.',
                description: `Counseling type cannot be ${status}.`,
              })
            }
            this.fetchDataTable()
            this.toggleModal()
            this.isSubmit = false
            this.loadingActionModal = false
          },
          onCancel: () => {
            this.isSubmit = false
            this.loadingActionModal = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.isEdit ? 'Edit' : 'Add',
        })
      } else {
        this.$notification.error({
          message: 'Error.',
          description: 'All field is required.',
        })
        this.isSubmit = false
      }
    },
    async fetchDataTable() {
      try {
        this.loadingTable = true
        const { data = [], total } = await this.$store.dispatch('counseling/FETCH_COUNSELING_TYPE', { page: this.pagination.current })
        this.dataTable = data.map(dat => ({ ...dat, key: dat.id }))
        this.pagination = { ...this.pagination, total }
        this.loadingTable = false
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      // let order = 'ASC'
      // let sortBy = ''
      pager.current = pagination.current
      this.pagination = pager
      // if (sorter) {
      //   sortBy = sorter.field
      //   // console.log(sorter)
      //   if (sorter.order === 'ascend') {
      //     order = 'ASC'
      //   } else if (sorter.order === 'descend') {
      //     order = 'DESC'
      //   }
      // }
      this.fetchDataTable()
    },
  },
  created() {
    this.fetchDataTable()
  },
}
</script>

<style>
</style>
